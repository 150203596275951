import React from "react";
import { Link, navigate } from "gatsby";
import { Grid, Flex, Box, Text, Button } from "theme-ui";
import { Helmet } from "react-helmet";

import { GrMenu } from "react-icons/gr";
import { trackButtonCta, trackButtonClick, Events } from "./analytics";
import { Routes } from "../constants/routes";
import Cookies from "universal-cookie";
import { CAMPAIGN_ID, AFTERWORD_COOKIE_PARAMS } from "../constants/cookies";

/** @jsx jsx */
import { jsx } from "theme-ui";
import { IS_BROWSER, scrollToElm } from "./dom";

const DENYLIST_PATHS = [
  "/admin/",
  "/login/",
  "/arbor/",
  "/arbor-directors/",
  "/canada/",
];
const DENYLIST_STARTSWITH_PATHS = ["/r/", "/stories/", "/free-"];

const DEFAULT_NAV_LINKS = (
  <>
  </>
);

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      announcementMessage: null,
    };

    let { location } = props;

    if (!IS_BROWSER) {
      return undefined;
    }

    let urlParams = new URLSearchParams(location?.search);
    if (
      urlParams.get("domainRedirect") &&
      urlParams.get("domainRedirect") === "planafuneral.com"
    ) {
      this.state.announcementMessage = "Plan a Funeral.com is now Afterword";
    } else if (
      urlParams.get("ref") &&
      urlParams.get("ref") === "producthunt.com"
    ) {
      this.state.announcementMessage = "Hello, Product Hunt!";
    } else if (
      urlParams.get("utm_campaign") &&
      urlParams.get("utm_campaign") === "nfda2022"
    ) {
      this.state.announcementMessage = "Welcome, NFDA Members! ";
    } else if (
      (urlParams.get("utm_source") &&
        urlParams.get("utm_source") === "fortune") ||
      (window &&
        window?.document?.referrer &&
        new URL(window.document.referrer).hostname === "fortune.com")
    ) {
      if (location.pathname === "/") {
        this.state.announcementMessage =
          "Looking to enhance your GPL online? Get more info here";
        const cookies = new Cookies();
        cookies.set(CAMPAIGN_ID, "fortune", AFTERWORD_COOKIE_PARAMS);
      }
    }
  }

  handleClick = (e) => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  render() {
    let {
      hideLinks,
      buttonOverride,
      buttonOverrideClickHandler,
      staticHeaderLink,
      navLinksOverride,
      location,
      variant,
    } = this.props;
    let shouldShowCanonical = true;
    let buttonText = "Contact us";
    let navLinks = navLinksOverride || DEFAULT_NAV_LINKS;

    let shouldShowLinks = !hideLinks || !!navLinksOverride;

    if (buttonOverride) {
      if (buttonOverride === "#get-started") {
        buttonText = "Contact us";
      } else if (buttonOverride === "#pwyc" || buttonOverride === "#signup") {
        buttonText = "Contact us";
      } else if (buttonOverride === "#contactus") {
        buttonText = "Contact us";
      } else if (buttonOverride === "#schedule") {
        buttonText = "Schedule Service";
      } else {
        buttonText = "Join Virtually";
      }
    }
    if (DENYLIST_PATHS.indexOf(location.pathname) > -1) {
      shouldShowCanonical = false;
    }
    DENYLIST_STARTSWITH_PATHS.forEach((startsWithPath) => {
      if (location.pathname.startsWith(startsWithPath)) {
        shouldShowCanonical = false;
      }
    });
    return (
      <header className="global-header">
        {shouldShowCanonical && (
          <Helmet>
            <link
              rel="canonical"
              href={`https://afterword.com${location.pathname}`}
            />
          </Helmet>
        )}
        <Grid
          id="grid-header"
          gap={2}
          sx={{ height: "60px" }}
          columns={["150px auto 120px", "150px auto 130px", "182px auto 150px"]}
        >
          <Box sx={{ height: "60px", marginLeft: ["0.5em", "0.5em", "2em"] }}>
            {!staticHeaderLink && (
              <Link
                id="logo-link"
                sx={{ variant: "links.logo" }}
                to={staticHeaderLink ? "" : Routes.HOME}
              >
                Afterword
              </Link>
            )}
            {staticHeaderLink && (
              <Text
                sx={{
                  variant: "links.logo",
                }}
              >
                Afterword
              </Text>
            )}
          </Box>
          <Flex
            sx={{
              marginLeft: 0, // variant === "health" ? 0 : [4, 1, 4],
              marginRight: 2,
              justifyContent: "flex-end",
              // variant === "health" ? "flex-end" : ["flex-end", "flex-start"],
              alignItems: "center",
            }}
          >
            <div
              sx={{
                display: ["none", "inherit"],
              }}
            >
              {shouldShowLinks && navLinks}
            </div>
            {shouldShowLinks && (
              <div
                role="button"
                tabIndex="0"
                sx={{
                  display: ["grid", "none"],
                  width: "24px",
                  height: "24px",
                  marginRight: "12px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onKeyDown={this.handleClick}
                onClick={this.handleClick}
              >
                <GrMenu color="plain" className="svg-icon" />
                {this.state.isDrawerOpen}
              </div>
            )}
          </Flex>
          {this.state.isDrawerOpen && (
            <Flex
              sx={{
                display: ["inherit", "none"],
                textAlign: "center",
                position: "absolute",
                marginTop: "60px",
                width: "100%",
                zIndex: 99,
                flexDirection: "column",
                backgroundColor: "white",
                borderBottom: "1px solid black",
              }}
              id="nav-drawer"
              onKeyDown={this.handleClick}
              onClick={this.handleClick}
            >
              {shouldShowLinks && navLinks}
            </Flex>
          )}
          <div>

          </div>

          {this.state.announcementMessage && (
            <Flex
              sx={{
                textAlign: "center",
                position: "absolute",
                marginTop: "60px",
                height: "30px",
                width: "100%",
                zIndex: 3,
                flexDirection: "column",
                backgroundColor: "buttonBlue",
                color: "white",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              id="announcement"
              onClick={(event) => {
                navigate("/gpl");
                this.setState({ announcementMessage: null });
              }}
            >
              <p
                sx={{
                  margin: "0",
                  lineHeight: "30px",
                  fontSize: ["12px", "14px", "18px"],
                }}
              >
                {this.state.announcementMessage}
                <button
                  sx={{
                    marginLeft: "2em",
                    backgroundColor: "transparent",
                    border: "1px solid beige",
                    borderRadius: "4px",
                    color: "beige",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "primary",
                    },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.setState({ announcementMessage: null });
                  }}
                >
                  X
                </button>
              </p>
            </Flex>
          )}
        </Grid>
      </header>
    );
  }
}
