import React from "react";
import { Link } from "gatsby";
import { Styled, Grid } from "theme-ui";

import { FaExternalLinkAlt } from "react-icons/fa";
import { Routes } from "../constants/routes";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

const ExternalLink = () => (
  <FaExternalLinkAlt size="12px" sx={{ marginBottom: "-1px", marginLeft: 1 }} />
);

export class Footer extends React.Component {
  render() {
    const { hideSubscribe, hideNonCompanyLinks } = this.props;

    return (
      <footer>
        {!hideSubscribe && (
          <section sx={{ backgroundColor: "darkBlue" }}>
            <div className="aw-content">
              <Grid
                gap={[0, 3, 4]}
                columns={["none", "7fr 5fr", "8fr 4fr"]}
                sx={{ alignItems: "center", color: "white" }}
              >
                <div>
                  <Styled.h4>
                    A death changes life forever—we're here for everything after
                  </Styled.h4>
                </div>
                <div></div>
              </Grid>
            </div>
          </section>
        )}

        <section>
          <div className="aw-content">
            <Grid
              gap={3}
              columns={["1fr", "1fr 1fr 1fr 1fr"]}
              sx={{
                lineHeight: 2,
              }}
            >
              <div>
                <Text
                  variant="links.logo"
                  sx={{ lineHeight: "1", marginBottom: "12px" }}
                >
                  Afterword
                </Text>
                <p>© {new Date().getFullYear()} Afterword Inc.</p>
              </div>
              {!hideNonCompanyLinks && (
                <div>
                  <p>
                    <b>Product</b>
                  </p>
                  <Link
                    sx={{ variant: "links.footer" }}
                    to={Routes.ONLINE_PLANNER}
                  >
                    Online Planner
                  </Link>
                  <br />
                  <Link
                    sx={{ variant: "links.footer" }}
                    to={Routes.FUNERAL_HOMES}
                  >
                    Products
                  </Link>
                  <br />
                  <Link
                    sx={{ variant: "links.footer" }}
                    to={Routes.CASE_STUDIES}
                  >
                    Case Studies
                  </Link>
                  <br />
                  <Link sx={{ variant: "links.footer" }} to={Routes.PRICING}>
                    Pricing
                  </Link>
                </div>
              )}
              {!hideNonCompanyLinks && (
                <div>
                  <p>
                    <b>Company</b>
                  </p>
                  <Link sx={{ variant: "links.footer" }} to={Routes.ABOUT}>
                    About
                  </Link>
                  <br />
                  <Link sx={{ variant: "links.footer" }} to={Routes.CONTACT}>
                    Contact Us
                  </Link>
                  <br />
                  <Link
                    sx={{ variant: "links.footer" }}
                    to={Routes.FUNERAL_FAQS}
                  >
                    FAQs
                  </Link>
                  <br />
                  <a
                    sx={{ variant: "links.footer" }}
                    target="_blank"
                    rel="noreferrer noopener"
                    href={Routes.CAREERS_NOTION}
                  >
                    Careers
                    <ExternalLink />
                  </a>
                </div>
              )}
              {!hideNonCompanyLinks && (
                <div>
                  <p>
                    <b>Legal</b>
                  </p>
                  <Link sx={{ variant: "links.footer" }} to={Routes.TERMS}>
                    Terms of Service
                  </Link>
                  <br />
                  <Link sx={{ variant: "links.footer" }} to={Routes.PRIVACY}>
                    Privacy
                  </Link>
                  <br />
                  <Link sx={{ variant: "links.footer" }} to={Routes.SECURITY}>
                    Security
                  </Link>
                  <br />
                  <a
                    sx={{ variant: "links.footer" }}
                    target="_blank"
                    href="https://afterword.trustshare.com/home"
                  >
                    Compliance
                    <ExternalLink />
                  </a>
                </div>
              )}
            </Grid>
          </div>
        </section>
      </footer>
    );
  }
}
